import React from 'react';
import { makeStyles, useMediaQuery, useTheme, Box } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CarCard from '../car-card/CarCard.component';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import Text from '../common/text.component';

const CARD_HEIGHT_DESKTOP = '26.75rem';

const useStyle = makeStyles(
  (theme) => ({
    carContainer: {
      display:'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      position: 'relative',
      cursor: 'pointer',
      border: `2px dashed ${theme.palette.grayScale.g300}`,
      borderRadius: theme.borderRadius.main,
      [theme.breakpoints.up('md')]: {
        width: '18.75rem',
        minHeight: CARD_HEIGHT_DESKTOP,
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        minHeight: '145px',
      },
    },
    carCard: {
      margin: '0',
      width: '100%',
      height: '100%',
      boxSizing: 'border-box',
      padding: 'inherit',
      [theme.breakpoints.up('md')]: {
        minHeight: CARD_HEIGHT_DESKTOP,
      },
    },
    addIcon: {
      color: theme.palette.secondary.main,
      marginBottom: '1rem',
      fontSize: '2.2rem',
    },
    deleteIcon: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      fontSize: '1.25rem',
      color: theme.palette.primary.main,
      marginBottom: '1rem',
    },
    text: {
      color: theme.palette.primary.main,
    },
  })
);

const AddCar = ({
  id,
  car = null,
  onAddCarClick = () => {},
  onRemoveCarClick = () => {},
  className }) => {

  const classes = useStyle();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const empty = !car;

  function handleAddCar() {
    if (!empty) {
      return;
    }
    onAddCarClick();
  }

  function handleRemoveCar() {
    onRemoveCarClick(id);
  }

  return(
    <Box
      value={id}
      className={clsx(classes.carContainer, { [className]: !empty })}
      onClick={handleAddCar}>
      { empty
        ?
        <>
          <AddCircleIcon className={classes.addIcon} />
          <Text className={classes.text}>
              Agregá un auto
          </Text>
        </>
        : ( <>
          { mobile
            ?
            <CarCard
              disableHover
              className={classes.carCard}
              compact
              data-test-id="car-card"
              car={car}
              isComparador={true}/>
            :
            <CarCard
              disableHover
              className={classes.carCard}
              data-test-id="car-card"
              car={car}
              isComparador={true}/>
          }
          <DeleteIcon className={classes.deleteIcon} onClick={handleRemoveCar} />
        </>
        )
      }
    </Box>
  );
};

export default AddCar;
