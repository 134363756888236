import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import AddCar from './AddCar.component';
import Text from '../common/text.component';
import Select from '../common/Select.component';
import clsx from 'clsx';
import Notification from '../common/Notification';

const first = '#1495C5';
const second = '#B68AF8';
const third = '#FFDB94';

const LABEL_CATEGORIA = 'Seleccionar';
// eslint-disable-next-line max-len
const ALERT_TEXT = 'Con nuestra herramienta podés comparar las fichas técnicas de todos los autos disponibles en nuestro stock de Argentina';


const useStyle = makeStyles(
  (theme) => ({
    root: {
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    stretch: {
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'stretch'
      }
    },
    first: {
      padding: '4px',
      background: first,
      border: '1px',
      borderColor: theme.palette.grayScale.g200,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      }
    },
    second: {
      padding: '4px',
      background: second,
      border: '1px',
      borderColor: theme.palette.grayScale.g200,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      }
    },
    third: {
      padding: '4px',
      background: third,
      border: '1px',
      borderColor: theme.palette.grayScale.g200,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      }
    },
    addCar: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        minHeight: '145px',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
      }
    },
    filterRow: {
      marginBottom: '1.5rem'
    },
    columnValue: {
      padding: '20px',
      display:'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      fontWeight: theme.typography.weight.bold,
      borderBottom:`1px solid ${theme.palette.grayScale.g300}`,
      [theme.breakpoints.up('md')]: {
        marginBottom: '1.125rem',
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: '0.875rem',
      },
      '&.first,&.second,&.third': {
        padding: '8px',
        background: theme.palette.grayScale.g0,
        borderBottom: '1px solid'
      },
      '&.first': {
        borderBottomColor: first
      },
      '&.second': {
        borderBottomColor: second
      },
      '&.third': {
        borderBottomColor: third
      },
    },
  })
);

export default function Comparador({
  comparison,
  compareCars,
  sections,
  handleOpenSelectionDialog,
  handleRemoveCarClick,
  setFilter,
}) {
  const classes = useStyle();

  return(
    <Grid className={classes.root}
      container
      alignItems="center"
      justify="space-evenly"
      direction="row"
      spacing={2}>
      <Notification text={ALERT_TEXT}/>
      <Grid item xs={12}>
        <Text medium>
              Elegí al menos dos modelos para comparar
        </Text>
      </Grid>
      {[0, 1, 2].map( index => (
        <Grid key={`add-car-item-${index}`} className={classes.addCar} item>
          <AddCar
            car={compareCars[index]}
            onAddCarClick={handleOpenSelectionDialog}
            onRemoveCarClick={handleRemoveCarClick(index)}
            data-test-id={`addCarComponent${index + 1}`}
            className={clsx({
              [classes.first]: index === 0,
              [classes.second]: index === 1,
              [classes.third]: index === 2,
            })}
          />
        </Grid>
      ))}
      {compareCars.length > 0 && <>
        <Grid item xs={12} className={clsx(classes.stretch, classes.filterRow)}>
          <Grid container>
            <Grid item xs={12} md={3}>
              <Box display="flex" alignItems="center" height="100%" marginBottom="1rem">
                <Text small bold>Características</Text>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box display="flex">
                <Select
                  id='select-categoria'
                  label={LABEL_CATEGORIA}
                  onChange={(sectionId) => setFilter(sectionId)}
                  options={sections.map( (s) => ({
                    label: s.titulo,
                    value: s.id
                  }))}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {comparison.map( comparisonRow => (<>
          <Grid item xs={12}>
            <Text normal light>{comparisonRow.label}</Text>
          </Grid>
          <Grid item xs={12} className={classes.stretch}>
            <Box display="flex" justifyContent="space-between">
              {comparisonRow.values.map( (columnValue, i) => (
                <Box
                  key={`compare-car-data-${comparisonRow.label}-${i}`}
                  flex="0 0 30%"
                  className={clsx(classes.columnValue, {
                    'first': columnValue !== undefined && i === 0,
                    'second': columnValue !== undefined && i === 1,
                    'third': columnValue !== undefined && i === 2,
                  })}
                >
                  {columnValue === null && '--'}
                  {typeof(columnValue) === 'boolean' ?
                    (columnValue ? 'SI' : 'NO') :
                    columnValue
                  }
                </Box>
              ))}
            </Box>
          </Grid>
        </>))}
      </>}
    </Grid>
  );
}
