import React, { useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import Comparador from '../../components/comparador/Comparador.component';
import { GET_MODELS, GET_BRANDS, GET_MODEL_YEARS, GET_MODEL_VERSIONS, GET_CAR_MASTER } from '../../queries';
import AddCarDialog from '../../components/comparador/AddCarDialog.component';

const CHARACTERISTICS_ID = 'characteristics';


const ComparadorContainer = ({ car }) => {
  const [ fields, setFields ] = useState({});

  const { data: brandData, loading: loadingBrands } = useQuery(GET_BRANDS);

  const { data: modelData, loading: loadingModels } = useQuery(GET_MODELS, {
    variables: { brandId: fields.brandId }
  });
  const { data: yearData, loading: loadingYears } = useQuery(GET_MODEL_YEARS, {
    variables: {
      brandId: fields.brandId,
      modelId: fields.modelId,
    }
  });
  const { data: versionData, loading: loadingVersiones } = useQuery(GET_MODEL_VERSIONS, {
    variables: {
      brandId: fields.brandId,
      modelId: fields.modelId,
      year: fields.year,
    }
  });
  // Lógica de procesamiento de ficha técnica
  const getCharacteristics = (sheet) => {
    return sheet.find(c => c.id === CHARACTERISTICS_ID);
  };
  const filteredSections = (ss) => {
    return ss.filter( s => !filter || s.id === filter)
      .map( s => s.data )
      .reduce((previous, current) => previous.concat(current), []);
  };
  const getSeccionesDefault = () => {
    if (!car) {
      return [];
    }
    const caracteristicas = getCharacteristics(car.dataSheet);
    const secciones = caracteristicas?.sections.map( s => ({ titulo: s.title, id: s.id }));
    return secciones;
  };
  const buildComparison = (cd) => {
    if(cd.length === 0) return [];
    const result = [];
    const data0 = cd[0];
    const data1 = cd[1] || [];
    const data2 = cd[2] || [];
    for (let i = 0; i < data0.length; i++) {
      const label = data0[i].label;
      const values = [data0[i].value, data1[i]?.value, data2[i]?.value];
      result.push({
        label,
        values
      });
    }
    return result;
  };

  // State management

  const [showDialog, setShowDialog] = useState(false);
  const handleOpenSelectionDialog = () => {
    setShowDialog(true);
  };
  const handleCloseDialog = () => setShowDialog(false);
  const [sections, setSections] = useState(getSeccionesDefault());
  const [filter, setFilter] = useState(null);
  const [compareCars, setCompareCars] = useState(car ? [car] : []);
  const [compareDetails, setCompareDetails] = useState(car ? [getCharacteristics(car.dataSheet).sections] : []);

  const [getCar] = useLazyQuery(GET_CAR_MASTER, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (carData) => {
      const newCars = [...compareCars];
      newCars.push(carData.carMaster);
      setCompareCars(newCars);
      const newCompareDetails = newCars.map( c => getCharacteristics(c.dataSheet).sections);
      setCompareDetails(newCompareDetails);
      if(sections.length === 0) {
        const caracteristicas = getCharacteristics(newCars[0].dataSheet);
        const secciones = caracteristicas?.sections.map( s => ({ titulo: s.title, id: s.id }));
        setSections(secciones || []);
      }
    }
  });

  const handleOnSelectBrand = (brand) => {
    const newFields = { ...fields };
    newFields['brand'] = brand.name;
    newFields['brandName'] = brand.name;
    if (brand.id) newFields['brandId'] = brand.id[0];
    delete newFields['model'];
    delete newFields['modelName'];
    delete newFields['modelId'];
    delete newFields['year'];
    delete newFields['yearName'];
    delete newFields['version'];
    delete newFields['versionName'];
    setFields(newFields);
  };

  const handleOnSelectModel = (model) => {
    const newFields = { ...fields };
    newFields['model'] = model.name;
    newFields['modelName'] = model.name;
    if (model.id) newFields['modelId'] = model.id[0];
    delete newFields['year'];
    delete newFields['yearName'];
    delete newFields['version'];
    delete newFields['versionName'];
    setFields(newFields);
  };

  const handleOnSelectYear = (year) => {
    const newFields = { ...fields };
    newFields['year'] = year;
    newFields['yearName'] = year;
    delete newFields['version'];
    delete newFields['versionName'];
    setFields(newFields);
  };

  const brands = brandData?.brands || [];
  const models = modelData?.models || [];
  const years = yearData?.years || [];
  const versions = versionData?.versions || [];

  const handleAddCarClick = async (id) => {
    getCar({
      variables: {
        id
      }
    });
    setFields({});
    setShowDialog(false);
  };

  const handleRemoveCarClick = (index) => () => {
    const newCars = [...compareCars];
    newCars.splice(index, 1);
    setCompareCars(newCars);
    const newCompareDetails = newCars.map( c => getCharacteristics(c.dataSheet).sections);
    setCompareDetails(newCompareDetails);
  };

  return <>
    <Comparador
      comparison={buildComparison(compareDetails.map(filteredSections))}
      compareCars={compareCars}
      sections={sections}
      handleOpenSelectionDialog={handleOpenSelectionDialog}
      handleRemoveCarClick={handleRemoveCarClick}
      setFilter={setFilter}
    />
    <AddCarDialog
      open={showDialog}
      fields={fields}
      brands={brands}
      models={models}
      years={years}
      versions={versions}
      loadingBrands={loadingBrands}
      loadingModels={loadingModels}
      loadingYears={loadingYears}
      loadingVersiones={loadingVersiones}
      onSelectBrand={handleOnSelectBrand}
      onSelectModel={handleOnSelectModel}
      onSelectYear={handleOnSelectYear}
      onAddCarClick={handleAddCarClick}
      onClose={handleCloseDialog}
    />
  </>;
};

export default ComparadorContainer;
