import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Text from '../common/text.component';
import { DialogContent, useMediaQuery, Box, DialogActions } from '@material-ui/core';
import Button from '../common/button.component';
import Select from '../common/Select.component';
import Dialog from '../common/Dialog.component';
import { theme as globalTheme } from '../testUtils';

const LABEL_BRAND = 'Marca';
const LABEL_MODEL = 'Modelo';
const LABEL_YEAR = 'Año de fabricación';
const LABEL_VERSION = 'Versión';

const useStyles = makeStyles((theme) => ({
  dialog: {
    [theme.breakpoints.up('md')]: {
      padding: '2rem 2.5rem 1.25rem',
      minHeight: '28rem',
      minWidth: '26rem'
    }
  },
  container: {
    margin: '0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap: 'nowrap',
    height: '100%',
  },
  logo: {
    height: '2.5rem',
    width: '7rem',
    display: 'flex',
  },
  button: {
    width: '12.5rem',
    minHeight: '3.3rem',
    textTransform: 'none',
    fontSize: '1.25rem',
    justifySelf: 'flex-end',
  },
  select: {
    margin: '2rem',
  },
  closeButton: {
    fontSize: '1.25rem',
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

export default function AddCarDialog({
  open,
  fields,
  brands = [],
  models = [],
  years = [],
  versions = [],
  loadingBrands = false,
  loadingModels = false,
  loadingYears = false,
  loadingVersions = false,
  onSelectBrand = () => { },
  onSelectModel = () => { },
  onSelectYear = () => { },
  onAddCarClick,
  onClose
}) {
  const [version, setVersion] = useState();
  const classes = useStyles();
  const fullScreen = useMediaQuery(globalTheme.breakpoints.down('sm'));

  function handleSelectVersion(value) {
    setVersion(value);
  }

  const handleAddCarClick = () => {
    onAddCarClick(version);
    clearDialog();
  };

  const handleClose = () => {
    clearDialog();
    onClose();
  };

  const clearDialog = () => {
    setVersion(null);
  };
  const disableAddBtn = !fields.brand || !fields.model || !fields.year || !version;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
    >
      <DialogContent className={classes.dialog} dividers>
        <Box className={classes.container}>
          {onClose ? (
            <CloseIcon className={classes.closeButton} onClick={handleClose} />
          ) : null}
          <form className={classes.container}>
            <Box margin="2rem auto 0.65rem">
              <Text>
                Selecciona un modelo para comparar
              </Text>
            </Box>
            <Select
              id='select-Brand'
              data-test-id='select-Brand'
              value={fields.brand}
              label={LABEL_BRAND}
              loading={loadingBrands}
              onChange={onSelectBrand}
              options={brands.map((b) => ({
                label: b.name,
                value: b,
                key: b.id[0]
              }))} />
            <Select
              id='select-model'
              data-test-id='select-model'
              value={fields.model}
              disabled={!fields.brand}
              label={LABEL_MODEL}
              loading={loadingModels}
              onChange={onSelectModel}
              options={models.map((mod) => ({
                label: mod.name,
                value: mod,
                key: mod.id[0]
              }))} />
            <Select
              id='select-year'
              data-test-id='select-year'
              value={fields.year}
              disabled={!fields.model}
              label={LABEL_YEAR}
              loading={loadingYears}
              onChange={onSelectYear}
              options={years.map((year) => ({
                label: year.label,
                value: year.year
              }))} />
            <Select
              id='select-version'
              value={version}
              disabled={!fields.year}
              label={LABEL_VERSION}
              loading={loadingVersions}
              onChange={handleSelectVersion}
              options={versions.map((ver) => ({
                label: ver.name,
                value: ver.id
              }))} />
          </form>
          {!fullScreen &&
            <Box position="absolute" bottom="1.25rem">
              <Button
                primary
                data-test-id="boton-agregar"
                disabled={disableAddBtn}
                className={classes.button}
                onClick={handleAddCarClick}
                variant="contained"
                color="primary"
                type="submit" >
                Agregar
              </Button>
            </Box>}
        </Box>
      </DialogContent>
      {fullScreen && <DialogActions>
        <Box display="flex" width="100%" justifyContent="center">
          <Button
            data-test-id="boton-agregar-mob"
            disabled={disableAddBtn}
            onClick={handleAddCarClick}>Agregar
          </Button>
        </Box>
      </DialogActions>}
    </Dialog>
  );
}
