import React from 'react';
import Layout from '../components/layouts/layout.component';
import Text from '../components/common/text.component';
import ComparadorContainer from '../containers/comparador/Comparador.container';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const PAGE_TITLE = 'Comparador - Carmuv';
const META_DESCRIPTION = 'Utilizá nuestro comparador y conocé el valor diferencial de tu vehículo frente a otros. Toda la información en el momento y de manera clara.';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')] : {
      padding: `0 ${theme.space.horizontal.mobile}`,
      paddingBottom: theme.space.vertical.mobile
    },
    [theme.breakpoints.up('md')] : {
      padding: `0 ${theme.space.horizontal.desktop}`,
      paddingBottom: theme.space.vertical.desktop
    },
  },
  title: {
    marginBottom: theme.spacing(3),
    width: '100%',
  },
  item: {
    width: '100%',
  }
}));

const ComparadorPage = ({ location, pageContext }) => {
  const classes = useStyles();
  const { car } = location.state || {};
  return (
    <Layout
      title={PAGE_TITLE}
      location={location}
      pageContext={pageContext}
      showSubHeader
      description={META_DESCRIPTION}
    >
      <Grid className={classes.container} container direction="column">
        <Grid className={classes.title} item>
          <Text bold xLarge>Comparador</Text>
        </Grid>
        <Grid className={classes.item} item>
          <ComparadorContainer car={car} />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default ComparadorPage;
